<template>
  <div class="wrapper">
    <el-dialog
      v-model="_show"
      :title="$t('account_manager.edit_user.safety_verification')"
      width="440px"
      @close="emits('update:show', false)"
    >
      <div class="tips">
        <svg-icon name="info"></svg-icon>
        <span>{{ $t('account_manager.edit_user.safety_info') }}</span>
      </div>
      <div class="content">
        <p v-if="_type === 'phone'">{{ $t('account_manager.edit_user.used_phone') }} +86 {{phone?.replace(reg1, '$1****$3')}}</p>
        <p v-else>{{ $t('account_manager.edit_user.used_email') }} {{email?.replace(reg,va=>email[0] + '*'.repeat(va.length - 1))}}</p>
        <el-form
          :model="model"
          :rules="rules"
          style="width: 100%"
          ref="emailRef"
        >
          <el-form-item prop="email_code">
            <div class="code-item">
              <el-input type="text" maxlength="6" :placeholder="$tInput()" v-model="model.email_code"/>
              <verificationCodeBtn v-show="_type === 'phone'" ref="verificationRef" @send="sendEmailCodeFn" :loading="loading"/>
              <verificationCodeBtn v-show="_type === 'email'" ref="emailBtnRef" @send="sendEmailCodeFn" :loading="loading"/>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="footer">
          <div class="change" @click="change" v-if="showChange">{{ $t('account_manager.edit_user.change_verification', [_type === 'email' || _type === 'api_key' ? $t('label.phone') : $t('label.email')]) }}</div>
          <div v-else style="width:112px;"></div>
          <span class="dialog-footer">
            <el-button @click="cancel">{{ $t('action.cancel') }}</el-button>
            <el-button :disabled="disabled" type="primary" @click="next">{{ $t('action.confirm') }}</el-button>
          </span>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, computed, onMounted } from 'vue'
import verificationCodeBtn from './verification-code-btn.vue'
import { sendEmailCode, verifyEmailCode, sendPhoneCode, verifyPhoneCode } from '@/services/account'
import { message } from '@/hooks/useUI'
import { t } from 'app/i18n'

const emailRef = ref()
const verificationRef = ref()
const emailBtnRef = ref()
const reg = ref(/^(.{1})(.+)(?=.{1}@)/g)
const reg1 = ref(/^(\d{3})(\d{4})(\d{4})$/)
const legalCode = ref(false)
const model = ref({
  email_code: ''
})
const loading = ref(false)

const emits = defineEmits(['update:show', 'verifySuccess'])

const props = defineProps({
  show: {
    default: false,
    type: Boolean
  },
  email: {
    type: String
  },
  phone: {
    type: String
  },
  type: {
    type: String,
    default: 'email'
  }
})

const _show = ref(props.show)
const _type = ref(props.type)
onMounted(() => {
  if (_type.value === 'api_key') {
    if (props.email) {
      _type.value = 'email'
    } else {
      _type.value = 'phone'
    }
  }
})
const showChange = computed(() => {
  if (props.type === 'api_key') return true
  return false
})

const validateCode = (rule, value, callback) => {
  if (value) {
    if (value.length < 6) {
      callback(new Error(t('account_manager.edit_user.verify_code_length')))
    }
  } else {
    callback(new Error(t('account_manager.edit_user.verify_code')))
  }
}

const rules = ref({
  email_code: [
    { message: t('account_manager.edit_user.verify_code'), trigger: 'change' },
    { validator: validateCode, trigger: 'blur' }
  ]
})
const change = () => {
  if (_type.value === 'email') {
    if (!props.phone) {
      message(t('account_manager.edit_user.phone_required'), 'error')
    } else {
      _type.value = 'phone'
    }
  } else if (_type.value === 'phone') {
    if (!props.email) {
      message(t('account_manager.edit_user.email_required'), 'error')
    } else {
      _type.value = 'email'
    }
  }
}

const verifyCodeFn = () => {
  if (_type.value === 'phone') {
    verifyPhoneCode({
      code: model.value.email_code,
      action: props.type === 'email' ? 0 : props.type === 'phone' ? 1 : 2
    }).then(res => {
      if (res.code && res.code !== 0) {
        if (res.code === 1003) {
          message(t('account_manager.edit_user.err_code'), 'error')
        } else {
          message(res.message, 'error')
        }
        legalCode.value = false
      } else {
        legalCode.value = true
      }
    })
  } else {
    verifyEmailCode({
      code: model.value.email_code,
      action: props.type === 'email' ? 0 : props.type === 'phone' ? 1 : 2
    }).then(res => {
      if (res.code && res.code !== 0) {
        if (res.code === 1003) {
          message(t('account_manager.edit_user.err_code'), 'error')
        } else {
          message(res.message, 'error')
        }
        legalCode.value = false
      } else {
        legalCode.value = true
      }
    })
  }
}
t('account_manager.edit_user.err_code')
const sendEmailCodeFn = () => {
  loading.value = true
  console.log(props.type)
  if (_type.value === 'phone') {
    sendPhoneCode({ action: props.type === 'email' ? 0 : props.type === 'phone' ? 1 : 2 }).then(res => {
      console.log(res)
      if (res.code && res.code !== 0) {
        if (res.code === 1001) {
          message(t('account_manager.edit_user.code_obtain'), 'error')
        } else if (res.code === 2000) {
          message(t('account_manager.edit_user.code_exceed'), 'error')
        } else if (res.code === 2001) {
          message(t('account_manager.edit_user.frequency_too_fast'), 'error')
        } else if (res.code === 4001) {
          message(t('account_manager.edit_user.phone_unbinded'), 'error')
        } else {
          message(res.message, 'error')
        }
      } else {
        message(t('account_manager.edit_user.code_sended'), 'success')
        verificationRef.value.tackBtn()
      }
    }).finally(() => { loading.value = false })
  } else {
    sendEmailCode({ action: props.type === 'email' ? 0 : props.type === 'phone' ? 1 : 2 }).then(res => {
      console.log(res)
      if (res.code && res.code !== 0) {
        if (res.code === 1001) {
          message(t('account_manager.edit_user.code_obtain'), 'error')
        } else if (res.code === 2000) {
          message(t('account_manager.edit_user.code_exceed'), 'error')
        } else if (res.code === 2001) {
          message(t('account_manager.edit_user.frequency_too_fast'), 'error')
        } else if (res.code === 4000) {
          message(t('account_manager.edit_user.email_unbinded'), 'error')
        } else {
          message(res.message, 'error')
        }
      } else {
        message(t('account_manager.edit_user.code_sended'), 'success')
        emailBtnRef.value.tackBtn()
      }
    }).finally(() => { loading.value = false })
  }
}

function cancel() {
  // clearTimeout(state.timer)
  _show.value = false
}

function next() {
  emits('verifySuccess')
  _show.value = false
}

watch(() => model.value.email_code, (v) => {
  if (v.length === 6) {
    verifyCodeFn()
  }
})

const disabled = computed(() => {
  return model.value.email_code.length < 6 || !legalCode.value
})
</script>

<style lang="scss" scoped>
.wrapper {
  :deep(.el-dialog__body) {
    padding: 0 0 24px;
    .tips {
      background-color:var(--color-warning-tip);
      font-size: 14px;
      color: var(--color-text-1);
      line-height: 40px;
      padding-left: 24px;
      display: flex;
      align-items: center;
      svg {
        color: var(--color-warning-text);
        font-size: 16px;
        margin-right: 3px;
      }
    }
    .content {
      padding: 16px 24px 0px;
      .code-item {
        width: 100%;
        display: flex;
        justify-content:space-between;
        .el-button {
          height: 33px;
        }
      }
    }
  }
  :deep(.el-dialog__footer) {
    .footer {
      display: flex;
      justify-content:space-between;
      align-items: center;
      .change {
        font-size: 14px;
        color: var(--color-primary);
        cursor: pointer;
      }
    }
  }
}
</style>
